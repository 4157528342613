import {useState} from "react";

export type InputValueTypes = null | undefined | string;

export type InputValues = { [id: string]: InputValueTypes };

export const useInputChange = (initalValue: InputValues): [InputValues, (id: string, next: InputValueTypes) => void, () => void]  => {
  const [inputs, setInput] = useState<InputValues>(initalValue || {});

  const onChange = (id: string, next: InputValueTypes) => {
    setInput((state: InputValues) => ({ ...state, [id]: next }));
  };

  const onReset = () => {
    setInput((state: InputValues) => ({...state, ...initalValue}));
  };
  
  return [inputs, onChange, onReset];
}


