import {
  ICollection,
  SupportedLanguages,
  getTranslation,
} from "../../common/utils/language/index";

interface ITranslation {
  accountResetFailedTitle: string;
  accountResetFailedBody: string;
  accountResetSuccessfulTitle: string;
  accountResetSuccessfulBody: string;
  accountActivationSuccessfulTitle: string;
  accountActivationSuccessfulBody: string;
  accountActivationFailedTitle: string;
  accountActivationFailedBody: string;
  accountRecoverMailFailedTitle: string;
  accountRecoverMailFailedBody: string;
  accountRecoverMailSendTitle: string; 
  accountRecoverMailSendBody: string; 
  accountInactiveTitle: string;
  accountInactiveBody: string;
  accountCreatedTitle: string; 
  accountCreatedBody: string; 
  accountCreationFailedTitle: string;
  accountCreationFailedBody: string;
  loginFailedTitle: string;
  loginFailedBody: string;
}

export const Translation: ICollection<ITranslation> = {
  [SupportedLanguages.de]: {
    accountResetFailedTitle: "Passwort nicht geändert.",
    accountResetFailedBody: "Das Passwort konnte nicht geändert werden. Bitte versuchen Sie es erneut.",
    accountResetSuccessfulTitle: "Passwort geändert.",
    accountResetSuccessfulBody: "Das Passwort wurde erfolgreich aktualisiert.",
    accountActivationSuccessfulTitle: "Accountaktivierung erfolgreich.",
    accountActivationSuccessfulBody: "Der Account wurde aktiviert und kann nun zum Einloggen verwendet werden.",
    accountActivationFailedTitle: "Accountaktivierung fehlgeschlagen.",
    accountActivationFailedBody: "Der Account konnte nicht aktiviert werden. Bitte kontaktieren Sie den Administrator.",
    accountRecoverMailFailedTitle: "Wiederstehlungs-eMail fehlgeschlagen.",
    accountRecoverMailFailedBody: "Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator.",
    accountRecoverMailSendTitle: "Wiederherstellungs-eMail gesendet.",
    accountRecoverMailSendBody: "Bitte überprüfen Sie ihr eMail Postfach.",
    accountInactiveTitle: "Account nicht aktiviert.",
    accountInactiveBody: "Der Account wurde noch nicht aktiviert. Bitte überprüfen Sie ihr eMail Postfach.",
    accountCreatedTitle: "Account erstellt.",
    accountCreatedBody: "Der Account wurde erfolgreich angelegt. Bitte überprüfen Sie ihre eMail Postfach um ihren Account zu aktivieren.",
    accountCreationFailedTitle: "Accounterstellung fehlgeschlagen.",
    accountCreationFailedBody: "Der Account konnte nicht erstellt werden. Bitte überprüfen Sie ihre Eingaben oder versuchen Sie es erneut.",
    loginFailedTitle: "Login fehlgeschlagen.",
    loginFailedBody: "Die eingegebenen Login-Informationen existieren nicht. Bitte überprüfen Sie diese oder versuchen Sie es erneut.",
  },
  [SupportedLanguages.en]: {
    accountResetFailedTitle: "Unable to to set new password.",
    accountResetFailedBody: "The password could not be changed successfully. Please try again.",
    accountResetSuccessfulTitle: "Password successfully updated.",
    accountResetSuccessfulBody: "The password has been successfully updated.",
    accountActivationSuccessfulTitle: "Account activation successful.",
    accountActivationSuccessfulBody: "The account is activated and can be used to login.",
    accountActivationFailedTitle: "Account activation failed.",
    accountActivationFailedBody:   "Please try again or contact the Support team.",
    accountRecoverMailFailedTitle: "Failed to send recovery email.",
    accountRecoverMailFailedBody: "Please try again or contact the Support team.",
    accountRecoverMailSendTitle: "Account recovery email send.",
    accountRecoverMailSendBody: "Please check your inbox to preceed with account password reset.",
    accountInactiveTitle: "Account not activated.",
    accountInactiveBody: "Your entered account is not activated. Please ensure you have received an activation mail.",
    accountCreatedTitle: "Account created.",
    accountCreatedBody: "The account has been successfully created. Please check your mailbox in order to activate your account.",
    accountCreationFailedTitle: "Account creation failed.",
    accountCreationFailedBody: "Unable to create account. Please try again and ensure all form data are entered propertly.",
    loginFailedTitle: "Account login failed.",
    loginFailedBody: "The provided account credentials do not match. Please try again.",
  },
};

export const NotificationTranslation = getTranslation<ITranslation>(Translation);
