import * as React from "react";
import { Link } from "gatsby";
import clsx from "clsx";

interface IProps {
  className?: string;
  title: string;
  path: string;
}

export const LinkTo = (props: IProps) => {
  const { className, title, path } = props;
  return (
    <Link
      to={path}
      className={clsx(
        "block py-2 text-red-400 text-center transition duration-300 opacity-90 hover:opacity-100",
        className
      )}
    >
      {title}
    </Link>
  );
};
