import * as React from "react";

import { Card } from "../components/card/index";
import { FormLayout } from "../components/form-layout/index";
import { redirectWithMessageType } from "../utils/index";
import { ILoginResponseDto } from "../dto/index";
import { LoginTranslation } from "../translation/login/index";
import { PrimaryButton } from "../common/components/button/primary";
import { InputField, InputTypes } from "../common/components/input-field/index";
import { LinkTo } from "../common/components/link/index";
import { hasEmptyValue, redirectTo } from "../common/utils/gatsby/index";

import {
  OnRequestCallback,
  RestMethods,
} from "../common/hooks/http-request/index";

import {
  InputValues,
  useInputChange,
} from "../common/hooks/input-change/index";

import {
  MessageType,
  QueryParamNotification,
} from "../components/query-params-notification/index";

import LockIcon from "../common/assets/lock-icon.svg";

export enum Inputs {
  email = "email",
  password = "password",
}

interface IProps {
  data: InputValues;
  onRequest: OnRequestCallback<InputValues, ILoginResponseDto>;
}

const Footer = ({ data, onRequest }: IProps) => {
  return (
    <div className="flex flex-col-reverse justify-center w-full sm:flex-row">
      <LinkTo
        className="flex-1"
        title={LoginTranslation.secondaryButtonFooter}
        path="/new"
      />
      <PrimaryButton
        title={LoginTranslation.primaryButtonFooter}
        isDisabled={hasEmptyValue(data)}
        onClick={async () => {

          const response = await onRequest(
            "/api/accounts/login",
            RestMethods.post,
            data
          );

          if (response == null || response.status === 400) {
            return redirectWithMessageType(MessageType.loginFailed);
          }

          if (response.token == null) {
            return redirectWithMessageType(MessageType.accountInactive);
          }

          redirectTo(
            (process as any).env.LOGIN_SUCCESSFUL_REDIRECT,
            response.token,
          );
        }}
      />
    </div>
  );
};

const LoginPage = () => {
  const [inputs, setInputChange] = useInputChange({
    [Inputs.email]: "",
    [Inputs.password]: "",
  });

  return (
    <React.Fragment>
      <QueryParamNotification />
      <Card icon={<LockIcon />}>
        <FormLayout
          title={LoginTranslation.title}
          description={LoginTranslation.message}
          footer={(onRequest) => <Footer data={inputs} onRequest={onRequest} />}
        >
          <div className="mt-10 space-y-4">
            <InputField
              id={Inputs.email}
              label={LoginTranslation.eMailPlaceholder}
              inputType={InputTypes.text}
              placeholder="max.mustermann@company.at"
              onChange={setInputChange}
            />
            <InputField
              id={Inputs.password}
              label={LoginTranslation.passwordPlaceholder}
              inputType={InputTypes.password}
              placeholder="********"
              onChange={setInputChange}
            />
            <div>
              <LinkTo
                className="my-4 mr-1 text-right text-blue-400"
                title={LoginTranslation.forgotPassword}
                path="/recovery"
              />
            </div>
          </div>
        </FormLayout>
      </Card>
    </React.Fragment>
  );
};

export default LoginPage;
