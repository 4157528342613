import * as React from "react";

import clsx from "clsx";

import {
  Notification,
  IMessage,
} from "../../common/components/notification/index";

import { NotificationTranslation } from "../../translation/notification/index";
import { getUrlParams } from "../../common/utils/gatsby/index";

export enum MessageType {
  accountResetFailed = "account-reset-failed",
  accountResetSuccessful = "account-reset-successful",
  accountActivationSuccessful = "account-activation-successful",
  accountActivationFailed = "account-activation-failed",
  accountRecoverMailFailed = "account-recover-failed",
  accountRecoverMailSend = "account-recover-mail-send",
  accountInactive = "inactive-account",
  accountCreated = "created-account",
  accountCreationFailed = "failed-to-create-account",
  loginFailed = "login-failed",
}

const convertToMessage = (type: MessageType | null): IMessage | null => {
  switch (type) {
    case MessageType.accountCreated:
      return {
        title: NotificationTranslation.accountCreatedTitle,
        body: NotificationTranslation.accountCreatedBody,
      };
    case MessageType.accountCreationFailed:
      return {
        title: NotificationTranslation.accountCreationFailedTitle,
        body: NotificationTranslation.accountCreationFailedBody,
      };
    case MessageType.loginFailed:
      return {
        title: NotificationTranslation.loginFailedTitle,
        body: NotificationTranslation.loginFailedBody,
      };
    case MessageType.accountInactive:
      return {
        title: NotificationTranslation.accountInactiveTitle,
        body: NotificationTranslation.accountInactiveBody,
      };
    case MessageType.accountActivationFailed:
      return {
        title: NotificationTranslation.accountActivationFailedTitle,
        body: NotificationTranslation.accountActivationFailedBody,
      };
    case MessageType.accountActivationSuccessful:
      return {
        title: NotificationTranslation.accountActivationSuccessfulTitle,
        body: NotificationTranslation.accountActivationSuccessfulBody,
      };
    case MessageType.accountRecoverMailFailed:
      return {
        title: NotificationTranslation.accountRecoverMailFailedTitle,
        body: NotificationTranslation.accountRecoverMailFailedBody,
      };
    case MessageType.accountRecoverMailSend:
      return {
        title: NotificationTranslation.accountRecoverMailSendTitle,
        body: NotificationTranslation.accountRecoverMailSendBody,
      };
    case MessageType.accountResetFailed:
      return {
        title: NotificationTranslation.accountResetFailedTitle,
        body: NotificationTranslation.accountResetFailedBody,
      };
    case MessageType.accountResetSuccessful:
      return {
        title: NotificationTranslation.accountResetSuccessfulTitle,
        body: NotificationTranslation.accountResetSuccessfulBody,
      };
    default:
      return null;
  }
};

const getType = (): MessageType | null => {
  const params = getUrlParams();
  let type = Object.values(MessageType).find(
    (v) => params.get(v) != null
  ) as MessageType | null;
  return type;
};

const isSuccess = (type: MessageType | null) =>
  type === MessageType.accountCreated ||
  type === MessageType.accountActivationSuccessful ||
  type === MessageType.accountRecoverMailSend ||
  type === MessageType.accountResetSuccessful;

const getMessage = () => convertToMessage(getType());

interface IState {
  show: boolean;
  message: IMessage | null;
  type: MessageType | null;
}

export const QueryParamNotification = () => {
  const [state, setState] = React.useState<IState>({
    show: false,
    message: getMessage(),
    type: getType(),
  });

  React.useEffect(() => {
    const message = getMessage();
    if (message == null) {
      return;
    }

    let timer = setTimeout(() => {
      setState((state) => ({
        ...state,
        show: true,
        type: getType(),
        message,
      }));

      timer = setTimeout(
        () => setState((state) => ({ ...state, show: false })),
        5000
      );
    }, 1);

    return () => clearTimeout(timer);
  }, []);

  const { show, message, type } = state;

  return (
    <Notification
      show={show}
      message={message}
      isSuccessfulMessage={isSuccess(type)}
    />
  );
};
