import {
  ICollection,
  SupportedLanguages,
  getTranslation,
} from "../../common/utils/language/index";

interface ITranslation {
  title: string;
  message: string;
  passwordPlaceholder: string;
  eMailPlaceholder: string;
  forgotPassword: string;
  primaryButtonFooter: string;
  secondaryButtonFooter: string;
}

export const Translation: ICollection<ITranslation> = {
  [SupportedLanguages.de]: {
    title: "Willkommen",
    message: "Bitte verwenden Sie Ihre Account-Informationen um sich anzumelden.",
    passwordPlaceholder: "Passwort",
    forgotPassword: "Passwort vergessen?",
    eMailPlaceholder: "eMail",
    primaryButtonFooter: "Anmelden",
    secondaryButtonFooter: "Keinen Account?",
  },
  [SupportedLanguages.en]: {
    title: "Welcome",
    message: "Please enter your account credentials in order to continue the login process.",
    passwordPlaceholder: "Password",
    forgotPassword: "Forgot password?",
    eMailPlaceholder: "eMail",
    primaryButtonFooter: "Log in",
    secondaryButtonFooter: "Don't have an account?",
  },
};

export const LoginTranslation = getTranslation<ITranslation>(Translation);
