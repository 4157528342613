import * as React from "react";

import clsx from "clsx";

import { InputValueTypes } from "../../hooks/input-change/index";
import { isNumberInput } from "../../utils/input/index";
import { isEmptyString } from "../../utils/gatsby/index";

export enum InputTypes {
  text = "text",
  password = "password",
  date = "date",
  number = "number-only",
}

interface IProps {
  id: string;
  label?: string;
  className?: string;
  inputType: InputTypes;
  disabled?: boolean;
  placeholder?: string;
  value?: InputValueTypes;
  onChange?: (id: string, vaue: InputValueTypes) => void;
}

export const InputField = (props: IProps) => {
  const {
    className,
    id,
    disabled,
    label,
    inputType,
    placeholder,
    value,
    onChange,
  } = props;

  return (
    <div className={clsx(className ? className : "md:w-full")}>
      {label && (
        <label
          className={clsx(
            "block mb-3 ml-4 text-xs font-bold tracking-wide uppercase",
            disabled ? "text-gray-400" : "text-grey-darker"
          )}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <input
        className={clsx(
          "block w-full px-4 py-3 border bg-blue-10 rounded-full outline-none appearance-none transition duration-300 border-blue-50 focus:border-blue-200",
          disabled ? "text-gray-400" : null
        )}
        id={id}
        type={
          inputType === InputTypes.text || inputType === InputTypes.number
            ? InputTypes.text
            : inputType
        }
        placeholder={placeholder}
        disabled={disabled}
        value={value != null ? value : undefined}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const nextValue = event.currentTarget.value;

          if (onChange == null) {
            return;
          }

          if (isEmptyString(nextValue)) {
            return onChange && onChange(id, nextValue);
          }

          if (inputType === InputTypes.number) {
            return (
              isNumberInput(nextValue) &&
              onChange(id, event.currentTarget.value)
            );
          }

          onChange && onChange(id, nextValue);
        }}
      />
    </div>
  );
};
