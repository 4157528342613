import * as React from "react";

import clsx from "clsx";

export interface IMessage {
  title: String;
  body: String;
}

interface IProps {
  message: IMessage | null;
  show: boolean;
  isSuccessfulMessage: boolean;
}

export const Notification = (props: IProps) => {
  const { show, message, isSuccessfulMessage } = props;

  return (
    <div
      className={clsx(
        "fixed top-0 left-0 right-0 z-50 transition-all duration-500 mx-4",
        show  ? "mt-4 opacity-100 cursor-pointer" : "mt-0 opacity-0 pointer-events-none"
      )}
    >
      <div
        className={clsx(
          "max-w-md p-4 mx-auto text-center bg-white border shadow-xl rounded-3xl",
            isSuccessfulMessage
            ? "bg-blue-400 border-blue-400"
            : "bg-red-400 border-red-400"
        )}
      >
        <div className="mb-2 font-bold text-white">
          {message && message.title}
        </div>
        <div className="text-white">{message && message.body}</div>
      </div>
    </div>
  );
};
